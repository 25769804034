<script setup lang="ts">
import { useVuelidate } from '@vuelidate/core';
import type { BackgroundType } from '~/types/components/backgroundType';

const props = withDefaults(
    defineProps<{
        backgroundType?: BackgroundType;
        startValue?: number | null;
        showErrorMsg?: boolean;
    }>(),
    {
        backgroundType: 'light',
        startValue: null,
        showErrorMsg: false,
    },
);

const { isLoggedIn } = useUser();
const {
    product,
    sharedQuantity,
    toggleOverlay,
    minPurchaseIsInvalid,
    maxPurchaseIsInvalid,
    isInvalid,
    purchaseStepsIsInvalid,
    getQuantityRules,
    isAvailable,
} = useQuantityValidation();

const rules = computed(() => ({
    sharedQuantity: getQuantityRules(
        product.value?.minPurchase || 1,
        product.value?.maxPurchase || undefined,
        product.value?.purchaseSteps || 1,
    ),
}));

const { getPrice } = useProductPrices();
const productPrice = computed(() => getPrice(product.value?.productNumber));

const $v = useVuelidate(rules, { sharedQuantity });

const validateQuantity = async () => {
    $v.value.$touch();
    const valid = await $v.value.$validate();
    minPurchaseIsInvalid.value = $v.value.sharedQuantity.minValue.$invalid || $v.value.sharedQuantity.required.$invalid;
    purchaseStepsIsInvalid.value = $v.value.sharedQuantity.purchaseSteps.$invalid;
    if (Object.hasOwn($v.value.sharedQuantity, 'maxValue')) {
        maxPurchaseIsInvalid.value = $v.value.sharedQuantity.maxValue.$invalid;
    }
    isInvalid.value = !valid;
};

if (props.startValue) {
    sharedQuantity.value = props.startValue;
}
const isDisabled = computed(() => !isLoggedIn.value || !isAvailable.value || !!productPrice.value.unbuyable);

onMounted(() => {
    // validate quantity on change (from outside) (e.g. reset to startValue)
    // watch rules, because they may update a bit later (maxPurchase)
    watch(
        [sharedQuantity, rules],
        () => {
            validateQuantity();
        },
        { immediate: true },
    );
});
</script>

<template>
    <SharedInput
        :id="'quantity-' + product?.id"
        v-model="sharedQuantity"
        :background-type="backgroundType"
        :error="$v?.sharedQuantity.$error"
        :error-msg="showErrorMsg ? $t('fastOrder.invalidQuantity') : ''"
        :disabled="isDisabled"
        name="quantity"
        :label="$t('quantity')"
        :show-label="false"
        type="number"
        :min="product?.minPurchase"
        :max="product?.maxPurchase"
        :step="product?.purchaseSteps"
        @input="validateQuantity"
        @focus="(toggleOverlay(), validateQuantity())"
        @focusout="toggleOverlay()"
    >
        <template #prepend>
            <slot name="prepend" />
        </template>
        <template #append>
            <slot name="append" />
        </template>
    </SharedInput>
</template>
